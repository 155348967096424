import {combineReducers} from 'redux';


import auth, {
    newUser
 } from './auth';
import error from './error';


import {
    product, 
    products 
} from './product'

import {
    testimonial, 
    testimonials 
} from './testimonial'

import {
    youtube, 
    youtubes 
} from './youtube'

import {
    success, 
    successs 
} from './success'
 
export default combineReducers({
    auth,
    error,
    product,
    products,
    testimonial,
    testimonials,

    youtube,
    youtubes,


    success,
    successs
});

