import api from '../../services/api';
import { SET_PRODUCT, SET_PRODUCTS } from '../actionType';
import { addError, removeError } from './error';


 

 export const setProduct = product => ({
     type: SET_PRODUCT,
     product
 });
 export const setProducts = products => ({
     type: SET_PRODUCTS,
     products
 });
 
 

export const createProduct = data => {
 
    return async dispatch => {
        try { 
            const product = await api.call('post', 'product/new', data); 

                console.warn(product);
            if(product.status == 1) { 
                console.log(product.data);
                dispatch(setProduct(product.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(product.message))
            }
 

        } catch (err) {
            const error = err.response.data;
            console.log(error);
            dispatch(addError(error.err))
        }
    }
};



export const getProducts = () => {
    return async dispatch => {
        try {
            const products = await api.call('get', `product/all`); 
            dispatch(setProducts(products));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}


export const getProduct = path => {
    return async dispatch => {
        try {
            const product = await api.call('get', `product/${path}`); 
            dispatch(setProduct(product));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}



export const updateProduct = (id, data) => {
 
    return async dispatch => {
        try{ 
            const product = await api.call( 'post', `product/${id}`, data);
            
                console.warn(product);
            if(product.status == 1) { 
                console.log(product.data);
                dispatch(setProduct(product.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(product.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


export const removeProduct = ( data ) => {
 
    return async dispatch => {
        try{ 
            const product = await api.call( 'post', `product/del`, data);
            
            if(product.status == 1) { 

                const products = await api.call('get', `product/all`); 
                dispatch(setProducts(products));
                dispatch(removeError());
                
            } else { 
                dispatch(addError(product.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


 

 
