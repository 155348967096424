import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

 


const DEFAUT_STATE = {
    error: {
        message: null
    }
}; 


export const store = createStore(
    rootReducer,
    DEFAUT_STATE,
    compose(
        applyMiddleware(thunk),
    )
);


