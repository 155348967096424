export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const SET_AUTH_USER = 'SET_AUTH_USER';

export const SET_NEW_USER = 'SET_NEW_USER';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';




export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';



export const SET_TESTIMONIAL = 'SET_TESTIMONIAL';
export const SET_TESTIMONIALS = 'SET_TESTIMONIALS';


export const SET_YOUTUBE = 'SET_YOUTUBE';
export const SET_YOUTUBES = 'SET_YOUTUBES';


export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_SUCCESSS = 'SET_SUCCESSS';


