import React, { Component, Fragment } from "react"; 
import { connect } from "react-redux";
// import { getDashboard, getCurrentUser } from "../store/actions";
 

import { ValidatorForm, TextValidator} from './common/Validator/';

import { removeError, updatePassword } from "../store/actions";


 import NavBar from './common/NavBar';
 import MenuBar from './common/MenuBar';


class Dashboard extends Component {
    constructor(props) {
        super(props);



        this.state = { 
          menuToggle: false,
          password: '',
          npassword: '',
          ncpassword: ''
        } 

       
 
    }


    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.npassword) {
                return false;
            }
            return true;
        });


    }
    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    handleChange =  e => { 
        const { error, removeError } = this.props;

        if( error) {
            removeError();
        }
        const newState = {}
        newState[ e.target.name ] =  e.target.value;  
        this.setState( {...newState} );
      }
  
    doTogleMenu = ( menuToggle ) => { 
      this.setState( { menuToggle  });
    }


    handleSubmit = ( e ) => {
        const {  password, npassword, ncpassword } = this.state;

        const { updatePassword, auth } = this.props;
 
        updatePassword(auth.user.username, {
            cpassword : password,
            password: npassword
        });
  
  
      }
  

    render() {
        const { error } =  this.props;
        const { password, npassword, ncpassword } = this.state;
        return <> 
        
<NavBar {...this.props}  doTogleMenu = { this.doTogleMenu } menuToggle={ this.state.menuToggle }/>

<div className="container-fluid">
  <div className="row">
    

<MenuBar {...this.props} menuToggle={ this.state.menuToggle } />



    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Settings </h1>
        <div className="btn-toolbar mb-2 mb-md-0  d-none">
          
        </div>
      </div>







      <div className="mb-3">
          {
    error && <div>

{
     error.message   && <div className="alert alert-danger">
    <p className="m-0">{ error.message}</p>

</div>
}

        </div>
}
   
  </div>




      <div className="row mt-3 ">



 
 
    <div className="card card-body d-flex flex-row    ">
    <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
            className="w-100"
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 ">

              <div className="form-group">
                <label  className="text-capitalize">Current Password</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control w-100" 
                    name="password"
                    value={password}
                    type="password"
                    validators={['required', 'minStringLength:6']}
                    errorMessages={['current password is required.', 'At least 6 characters. ']}
                />
            </div>

            <div className="form-group">
                <label  className="text-capitalize">New Password</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control w-100" 
                    name="npassword"
                    value={npassword}
                    type="password"
                    validators={['required', 'minStringLength:6']}
                    errorMessages={['current password is required.', 'At least 6 characters. ']}
                />
            </div>
            <div className="form-group">
                <label  className="text-capitalize">Confirm Password</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control w-100" 
                    name="ncpassword"
                    value={ncpassword}
                    type="password"
                    validators={['required', 'minStringLength:6', 'isPasswordMatch']}
                    errorMessages={['current password is required.', 'At least 6 characters.', 'password don`t match']}
                />
            </div>
 
  

          </div>






          </div>

 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8">

          <button type="submit" className="btn btn-primary">Update</button>

      </div>
      </div>

         
        </ValidatorForm>

                
    </div> 

        


 

           











      </div>














    </main>
  </div>
</div>







         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    error: store.error
}), { removeError, updatePassword })(Dashboard);


