import React from 'react';
import { Redirect } from 'react-router-dom'; 
import Success from '../components/Success/';



 

const Page = ({   history, isAuthenticated, match }) => {
    if (!isAuthenticated) return <Redirect to="/login" />
 
    return (
        <div className="Success">  
             <Success history={history} match={ match }   />
        </div>
    );
}

 


export default Page;