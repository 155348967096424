import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'; 
import { connect } from "react-redux";
import { authUser } from "../store/actions";

import Logo from '../assets/image/logo/logo.png';
 


class Login extends Component {
    constructor(props) {
        super(props);



        this.state = { 
           username : 'admin@punarjeeva.com',
           password : '',
           disabled: false
        }
      


       
 
    }


     handleSubmit = (form) => {
        form.preventDefault();
        const { disabled } = this.state;

        if( ! disabled){
            const {
                username,
                password
            } = this.state;

            console.log( this.props );
    

            this.setState( { disabled : true }); 

            this.props.authUser( 'login', {
                username,
                password
            });

            
        }
    }


    handleChange = (event) => {
        const { disabled } = this.state;

        if( ! disabled){
        const vlaue = event.target.value;
        switch (event.target.name) {
            case "username":
                this.setState({
                    username: vlaue
                });
                break;
            case "password":
                this.setState({
                    password: vlaue
                });
                break;
            }
            
        }
    }


 
 
    componentWillReceiveProps (newProps, oldProps){ 
         
        if( newProps.error != oldProps.error && newProps.error.message ) { 
          this.setState( { disabled : false}); 
        }  
      
      }
       
      

    

    render() {
        
        const { username, password, disabled } = this.state;
        const { error } = this.props;

        const style = disabled ? { cursor: 'wait'} : { cursor: 'default'};
        return <>



        <div className="text-center" style={style}  >
            <form className="form-signin"  method="post"  onSubmit={this.handleSubmit} >
          <img className="mb-4 logo" src={Logo} alt=""  /> 
          <div className="form-group">
          <label htmlFor="inputEmail" className="sr-only" name="username" >Email address</label>
          <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autofocus name="username" onChange={this.handleChange} value={username}  />
        
          </div>
          <div className="form-group">
          <label htmlFor="inputPassword" className="sr-only" name="password">Password</label>
          <input type="password" id="inputPassword" className="form-control" placeholder="Password" required  name="password" onChange={this.handleChange} value={password}  />
          
          </div>
        <div className="checkbox mb-3">

        {
             error.message && ! disabled && <div className="alert alert-danger">
                <p className="m=0">{ error.message}</p>

            </div>
        }
           
          </div>
          <button className="btn btn-lg btn-primary btn-block" type="submit"  style={style}>Sign in</button>
          <p className="mt-5 mb-3 text-muted">&copy; Punarjeeva 2019</p>
        </form>
        </div>


         




        </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    error: store.error
}), { authUser })(Login);


