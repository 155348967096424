
import React, { Component, Fragment } from "react"; 
import {   Link } from 'react-router-dom'; 
import TimeAgo from 'react-timeago';

import { SERVER } from '../../services/api';
 
import { connect } from "react-redux";
// import { getViewTestimonial, getCurrentUser } from "../store/actions";
 
  

class ViewTestimonial extends Component {
    constructor(props) {
        super(props); 
 
    }

componentWillMount () {


        const { testimonial, history } = this.props;

        if ( typeof testimonial.user == "undefined") {
          history.push('.');
        }
}
 

 
    


    listImageList = images => {

      console.log( images );

      if(images) {

        return [images].map( (each, index) => 
                    <div key={index}  className="col pb-3 text-center">
                         <img  className="img-thumbnail mw-100"  src={  `${SERVER}/testimonial/${each}`   }/>
                                          
                    </div>

        );
      }

          }

    render() {
        const { testimonial } = this.props;

        return <> 


 

<div
 
     className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2 text-capitalize">{ testimonial.name || "view testimonial"}</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <span className="my-auto mr-3 text-muted"><TimeAgo date={testimonial.date } /></span>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Testimonials
      </Link>
    </div>
  </div> 






      <div className="row"> 

          <div className="col">

            <div className="row">

              <div className="col-sm-12 col-md-6">

                <div className="form">



                  <div className="form-group">
                      <lable>User Name</lable>  
                      <p className="form-control mt-2">{testimonial.user}</p> 
                  </div>


                  


                </div>


              </div>

              <div className="col-sm-12 col-md-6">
              <div className="form">

 

                <div className="form-group">
                    <lable>User Type</lable>  
                    <p className="form-control mt-2">{testimonial.type}</p> 
                </div>

                </div>

                </div>

<div className="col-sm-12 ">
<div className="form">


                  <div className="form-group">
                      <lable>Testimonial</lable>  
                      <p className="form-control mt-2" style={{ minHeight: '100px'}}>{testimonial.testimonial}</p> 
                  </div>


              </div>





              </div>

            </div>


               <div className="row mt-3">

              <div className="col-sm-12">

                <lable> User Image </lable> 


                <div className="row d-flex justify-content-center mt-2">

                  {
                    this.listImageList( testimonial.user_image )
                  }

                </div>

              </div>
              </div>

</div>
</div>

         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    testimonial: store.testimonial,
    error: store.error
}), {   })(ViewTestimonial);


