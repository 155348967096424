import {
    SET_AUTH_USER,
    SET_NEW_USER
} from '../actionType';

const DEFAULT_STATE = {
    isAuthenticated: false,
    user: {}
}



export default ( state = DEFAULT_STATE, action ) => { 
    switch (action.type) {
        case SET_AUTH_USER:
            return {
                isAuthenticated: !!Object.keys(action.user).length,
                user: action.user
            };
            
            break;
 

        default:

        return state;

    }
}

export const newUser = (state = {}, action) => {
    switch (action.type) {
        case SET_NEW_USER:
            return action.user;

        default:
            return state;

    }
};


