import api from '../../services/api';
import { SET_YOUTUBE, SET_YOUTUBES } from '../actionType';
import { addError, removeError } from './error';


 

 export const setYoutube = youtube => ({
     type: SET_YOUTUBE,
     youtube
 });
 export const setYoutubes = youtubes => ({
     type: SET_YOUTUBES,
     youtubes
 });
 
 

export const createYoutube = data => {
 
    return async dispatch => {
        try { 
            const youtube = await api.call('post', 'youtube/new', data); 

                console.warn(youtube);
            if(youtube.status == 1) { 
                console.log(youtube.data);
                dispatch(setYoutube(youtube.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(youtube.message))
            }
 

        } catch (err) {
            const error = err.response.data;
            console.log(error);
            dispatch(addError(error.err))
        }
    }
};



export const getYoutubes = () => {
    return async dispatch => {
        try {
            const youtubes = await api.call('get', `youtube/all`); 
            dispatch(setYoutubes(youtubes));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}


export const getYoutube = path => {
    return async dispatch => {
        try {
            const youtube = await api.call('get', `youtube/${path}`); 
            dispatch(setYoutube(youtube));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}



export const updateYoutube = (id, data) => {
 
    return async dispatch => {
        try{ 
            const youtube = await api.call( 'post', `youtube/${id}`, data);
            
                console.warn(youtube);
            if(youtube.status == 1) { 
                console.log(youtube.data);
                dispatch(setYoutube(youtube.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(youtube.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


export const removeYoutube = ( data ) => {
 
    return async dispatch => {
        try{ 
            const youtube = await api.call( 'post', `youtube/del`, data);
            
            if(youtube.status == 1) { 

                const youtubes = await api.call('get', `youtube/all`); 
                dispatch(setYoutubes(youtubes));
                dispatch(removeError());
                
            } else { 
                dispatch(addError(youtube.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


 

 
