import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'; 
import { connect } from "react-redux";
// import { getDashboard, getCurrentUser } from "../store/actions";
 

 import NavBar from './common/NavBar';
 import MenuBar from './common/MenuBar';


class Dashboard extends Component {
    constructor(props) {
        super(props);



        this.state = { 
          menuToggle: false
        } 

       
 
    }




  
    doTogleMenu = ( menuToggle ) => { 
      this.setState( { menuToggle  });
    }

    render() {
        

        return <> 
        
<NavBar {...this.props}  doTogleMenu = { this.doTogleMenu } menuToggle={ this.state.menuToggle }/>

<div className="container-fluid">
  <div className="row">
    

<MenuBar {...this.props} menuToggle={ this.state.menuToggle } />



    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Punarjeeva </h1>
        <div className="btn-toolbar mb-2 mb-md-0  d-none">
          <div className="btn-group mr-2">
            <button type="button" className="btn btn-sm btn-outline-secondary">Share</button>
            <button type="button" className="btn btn-sm btn-outline-secondary">Export</button>
          </div>
          <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <span data-feather="calendar"></span>
            This week
          </button>
        </div>
      </div>












      <div className="row mt-3 ">



 


<Link to="/product/" className="col-sm-12 col-md-4 mb-3 style-none  text-danger" > 
<div className="card card-body d-flex flex-row  each-link-tile d-flex align-items-center">

                <div>
                <i className="icofont-box mr-2"></i>
                </div>
                <div>
                  <h3 className="text-capitalize">  product </h3>
                </div>
</div>
            </Link>

        



            <Link to="/testimonial/"  className="col-sm-12 col-md-4 mb-3 style-none  text-success" > 
<div className="card card-body d-flex flex-row  each-link-tile d-flex align-items-center">
<div>
                <i className="icofont-users-alt-1 mr-2"></i>
                </div>
                <div>
                  <h3 className="text-capitalize">  testimonial </h3>
                </div>
                </div>
            </Link>



            <Link to="/youtube/" className="col-sm-12 col-md-4 style-none  mb-3 text-warning" > 
<div className="card card-body d-flex flex-row  each-link-tile d-flex align-items-center">
<div>
                <i className="icofont-youtube-play mr-2"></i>
                </div>
                <div>
                  <h3 className="text-capitalize">  youtube </h3>
                </div>
                </div>
            </Link>



            <Link to="/success/" className="col-sm-12 col-md-4 mb-3 style-none text-info " > 
<div className="card card-body d-flex flex-row  each-link-tile d-flex align-items-center">
 <div>
                <i className="icofont-brand-target mr-2"></i>
                </div>
                <div>
                  <h3 className="text-capitalize">  Success Stories  </h3>
                </div>
                </div>
            </Link>













      </div>














    </main>
  </div>
</div>







         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth
}), {  })(Dashboard);


