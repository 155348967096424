import axios from "axios";

let protocal = 'http:';

try {
    if (window.location.protocol == 'https:') {
        protocal = 'https:';
    }
} catch( err ){}

export const SERVER = `${protocal}//api.punarjeeva.tech`;
// export const SERVER = 'http://localhost/rest_api';


export const setToken = token => {
    if (token) { 
        axios.defaults.headers.common['punarjeeva-token'] = `${token}`;
    } else {
        delete axios.defaults.headers.common['punarjeeva-token'];
                localStorage.removeItem('userToken');
    }
};



export const call = async (method, path, data) => {


        try{


        const formData = new FormData();

        for ( let each in data) {
            formData.append(each, data[each]);
        }
 
        const response = await axios[method](`${SERVER}/api/${path}`,formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
      }); 


        return response.data;




        } catch( err ){  
            if( err.response.status === 401 ) { 
                setToken( null );
                window.location.reload();
            }
 

        return err.response.data;

        }



   


}

export const pdfCall = async (method, path, data) => {
    const response = await axios[method](`${SERVER}/api/${path}`, {
        responseType: 'blob'
    }, data);
    return response.data;
}


export const fileCall = async (method, path, data) => {

    const formData = new FormData();

    for ( let each in data) {
        formData.append(each, data[each]);
    }
 
//   {  "Content-Type": "multipart/form-data" },
/*

{
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

*/
    const response = await axios[method](`${SERVER}/rest_api/api/${path}`,formData);
    return response.data;
}



// { responseType: 'blob' }


export default {
    call,
    setToken,
    pdfCall,
    fileCall
};