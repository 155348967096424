import React from 'react';
import { Redirect } from 'react-router-dom'; 
import Youtube from '../components/Youtube/';



 

const Page = ({   history, isAuthenticated, match }) => {
    if (!isAuthenticated) return <Redirect to="/login" />
 
    return (
        <div className="Youtube">  
             <Youtube history={history} match={ match }   />
        </div>
    );
}

 


export default Page;