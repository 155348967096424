import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

import ImageCroper from '../ImageCrop';

class ImageSelector extends ValidatorComponent {
    constructor ( props ) {
        super(props); 
        this.doCrop = props.crop;

        this.state= {
            label : 'Choose file',
            files : [],
            newFiles: [],
            crop: { crop: props.crop}.crop,
            message :  props.imageSelectError,
            value: props.value
        }
 
    }
 


    fileChanged = e => {

        const file = e.target.files; 
        const files = [];
        for( let a = 0; a< file.length; a++) {
            files.push( file[a]);
        }
        const crop = this.doCrop; 
        this.setState( { files, crop }, e => this.fileSelected());
 
    }


    fileSelected = () => {

        const { files } = this.state;
        const message = ""; 
        this.setState({   message }); 
        if( ! this.state.crop ) { 
            this.doneProcess();
        }

 
    }


    doneProcess = ( newFiles  ) => {


        this.setState({ newFiles }, e => this.updateParent() );

 
    }


    doneCrop = ( newFiles ) => {
        const crop = false; 
        this.setState( { crop, newFiles });
        this.doneProcess( newFiles );
    }
    
    setEachImgPrev = (e, crop) => {
        var urlCreator = window.URL || window.webkitURL;
        console.log( typeof crop );
   var imageUrl = urlCreator.createObjectURL( crop );
 
   try { 
    e.src = imageUrl;
   } catch (error) {
       
   }


    }


    updateParent = () => {
        const { onChange, name } = this.props;
        const { newFiles, files } =  this.state; 
 
const value = newFiles; 

        onChange( {target: { name, value}} );
    }

    removeMe = ( index )=> {
        const { newFiles, files } = this.state;
        files.splice(index, 1);
        newFiles.splice(index, 1); 
        this.setState({ newFiles, files }, e => this.updateParent() );

    }

 


     getLabel = () => {

        const { label } = this.state;

        const  { value } = this.props;

        return value.map( each => each.name).join(", ") || label; 
     }

    
    render() {
        const { newFiles, message,  files, crop } = this.state;
        const { errorMessages, validators, requiredError, validatorListener, value, multiple, ...rest } = this.props;
 
        const label = this.getLabel();
        return (
            <div>

                {
                    files.length > 0 && crop ? <ImageCroper { ...this.props} files = { files} doneCrop={ this.doneCrop } /> : ""
                }


  <div className="custom-file" title={label}>
    <input  accept=".jpg, .jpge, .gif, .png" multiple={multiple} onChange={this.fileChanged} type="file" className="custom-file-input" id="inputGroupFile02" />
    <label className="custom-file-label text-truncate" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{label}</label>
  </div>
 
  



                {/* <textarea
                    {...rest}
                    ref={(r) => { this.input = r; }}
                >{rest.value}</textarea> */}

                {  this.errorText()}
               

                <div className="py-3 d-block px-2 image-review">
                        {
                           value && value.map( ( each, index) => {
                                return <div key={ index} className="row d-flex align-items-center mb-1 removable-prev">
                                        <span className="cros" onClick={  e => this.removeMe( index )}>
                                        <i className="icofont-close-squared-alt"></i>
                                        </span>
                                        <div className="col-sm-6 col-md-4">
                                            <img  className="img-thumbnail mw-100" ref = { e => this.setEachImgPrev( e, each.crop) } />
                                        </div>
                                        <div className="col-sm-6 col-md-8">
                                            <p>{ each.name }</p>
                                        </div>

                                    </div>
                            })
                        }
                </div>
            </div>
        );
    }
 
    errorText() {
        const { isValid } = this.state;
 
        if (isValid) {
            return null;
        }
 
        return (
            <div style={{ color: 'red' }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
 
export default ImageSelector;