import api from '../../services/api';
import { SET_TESTIMONIAL, SET_TESTIMONIALS } from '../actionType';
import { addError, removeError } from './error';


 

 export const setTestimonial = testimonial => ({
     type: SET_TESTIMONIAL,
     testimonial
 });
 export const setTestimonials = testimonials => ({
     type: SET_TESTIMONIALS,
     testimonials
 });
 
 

export const createTestimonial = data => {
 
    return async dispatch => {
        try { 
            const testimonial = await api.call('post', 'testimonial/new', data); 

                console.warn(testimonial);
            if(testimonial.status == 1) { 
                console.log(testimonial.data);
                dispatch(setTestimonial(testimonial.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(testimonial.message))
            }
 

        } catch (err) {
            const error = err.response.data;
            console.log(error);
            dispatch(addError(error.err))
        }
    }
};



export const getTestimonials = () => {
    return async dispatch => {
        try {
            const testimonials = await api.call('get', `testimonial/all`); 
            dispatch(setTestimonials(testimonials));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}


export const getTestimonial = path => {
    return async dispatch => {
        try {
            const testimonial = await api.call('get', `testimonial/${path}`); 
            dispatch(setTestimonial(testimonial));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}



export const updateTestimonial = (id, data) => {
 
    return async dispatch => {
        try{ 
            const testimonial = await api.call( 'post', `testimonial/${id}`, data);
            
                console.warn(testimonial);
            if(testimonial.status == 1) { 
                console.log(testimonial.data);
                dispatch(setTestimonial(testimonial.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(testimonial.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


export const removeTestimonial = ( data ) => {
 
    return async dispatch => {
        try{ 
            const testimonial = await api.call( 'post', `testimonial/del`, data);
            
            if(testimonial.status == 1) { 

                const testimonials = await api.call('get', `testimonial/all`); 
                dispatch(setTestimonials(testimonials));
                dispatch(removeError());
                
            } else { 
                dispatch(addError(testimonial.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


 

 
