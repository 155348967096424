
import React, { Component, Fragment } from "react"; 

import { Link} from 'react-router-dom';

import { connect } from "react-redux"; 

 

import { createYoutube } from '../../store/actions';

import {ListValidator, ValidatorForm, TextValidator, TextAreaValidator, ImageSelector} from '../common/Validator/';


class NewYoutube extends Component {
    constructor(props) {
        super(props); 

        this.state = {
          title : '',
          key: '',  
          description: '',
          disabled : false
        }
 
    }

    handleChange =  e => { 
      const newState = {}
      newState[ e.target.name ] =  e.target.value;  
      this.setState( {...newState} );
    }

    handleSubmit = ( e ) => {
      const {     
        title,
        key, 
        title_image,
        description } = this.state;
      const { createYoutube } = this.props;

       


       const new_youtube = {
         title,
        key, 
        description
       }

       
      

      console.log( new_youtube );


this.setState( { disabled : true });

      createYoutube( new_youtube );


    }





componentWillReceiveProps (newProps, oldProps){

  const { toast, history } = this.props;
  const { title } = this.state;

 

  if( newProps.youtube != oldProps.youtube && newProps.youtube ) {

        if( newProps.youtube.title === title) {
          toast.success("Youtube successfully added !", {
               position: toast.POSITION.LEFT_BOTTOM
             });

          this.setState({
                title : '',
                key: '',  
                description: '',
                disabled : false
              });
        }
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}
 

    render() {
        
      const {
        title ,
        key,  
        description,
        disabled 
      } = this.state;
 
        return <> 
 

 <div
    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">New Youtube</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Youtubes
      </Link>
    </div>
  </div>






      <div className="row"> 

          <div className="col position-relative">

          {
            disabled && <div className="disabled-form" ></div>
          }



          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 col-md-8">

              <div className="form-group">
                <label  className="text-capitalize">Title</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="title"
                    value={title}
                    validators={['required']}
                    errorMessages={['name is required.']}
                />
            </div>

            <div className="form-group">
                <label className="text-capitalize">Youtube Key</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="key"
                    value={key}
                    validators={['required']}
                    errorMessages={['key is required.']}
                />
            </div>

         


 
 

            <div className="form-group">
                <label className="text-capitalize">Description</label> 
                <TextAreaValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="description"
                    rows="4"
                    value={description} 
                    validators={['required']}
                    errorMessages={['description is required.']}
                />
            </div>



            


          </div>






          </div>

 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8">

          <button key="submit" className="btn btn-primary">Submit</button>

      </div>
      </div>

         
        </ValidatorForm>


            
          </div>
      </div>










         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    youtube: store.youtube,
    error: store.error
}), {  createYoutube })(NewYoutube);


