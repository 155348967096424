import React, { Component, Fragment } from "react"; 

import { Switch, Route} from 'react-router-dom';
import { connect } from "react-redux";
  import { ToastContainer, toast } from 'react-toastify';


// import { getYoutube, getCurrentUser } from "../store/actions";
 


 import NavBar from '../common/NavBar';
 import MenuBar from '../common/MenuBar';

 import AllYoutube from './ViewAll';
 import ViewYoutube from './View';
 import EditYoutube from './Edit';
 import NewYoutube from './New'; 


toast.configure({
  autoClose: 8000,
  draggable: false,
  //etc you get the idea
});



class Youtube extends Component {
    constructor(props) {
        super(props);



        this.state = { 
          menuToggle: false
        } 

      


       
 
    }

    doTogleMenu = ( menuToggle ) => { 
      this.setState( { menuToggle  });
    }

 
  /*

toast.success("Success Notification !", {
       position: toast.POSITION.TOP_CENTER
     });

     toast.error("Error Notification !", {
       position: toast.POSITION.TOP_LEFT
     });

     toast.warn("Warning Notification !", {
       position: toast.POSITION.BOTTOM_LEFT
     });

     toast.info("Info Notification !", {
       position: toast.POSITION.BOTTOM_CENTER
     });

     */ 

TabViews = ( ) => {
   const { path } = this.props.match; 
  return  (<main>
    <Switch> 
 

 <Route  exact  path={ `${path}/new` } component={( props ) => <NewYoutube {...props} toast = { toast } />} /> 
 <Route  exact  path={ `${path}/edit` } component={( props ) => <EditYoutube {...props} toast = { toast }  />} /> 
 <Route  exact  path={ `${path}/view` } component={( props ) => <ViewYoutube {...props} toast = { toast }  />} /> 
 <Route  exact  path={ `${path}` } component={( props ) => <AllYoutube {...props} toast = { toast }  />} /> 

 
        
    </Switch>
</main>);

}
  
  

 



componentWillReceiveProps (newProps, oldProps){

  const { toast, history } = this.props;

  if( newProps.youtube != oldProps.youtube && newProps.youtube ) {

    toast.success("Youtube successfully added !", {
         position: toast.POSITION.LEFT_BOTTOM
       });

    this.setState({
          name : '',
          title: '',
          feature: [],
          image: [],
          description: '',
          disabled : false
        });
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}
 

    

    render() {
   

        return <> 

<NavBar {...this.props}  doTogleMenu = { this.doTogleMenu } menuToggle={ this.state.menuToggle }/>

<div className="container-fluid">
  <div className="row">
    

<MenuBar {...this.props} menuToggle={ this.state.menuToggle } />
 
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4"> 
 
        { this.TabViews() }
       

       <ToastContainer />
    </main>
  </div>
</div>







         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth
}), {  })(Youtube);


