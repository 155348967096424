import { SET_SUCCESS, SET_SUCCESSS  } from '../actionType';


export const success = ( state = {}, action ) => { 
    
    switch(action.type) {
        case SET_SUCCESS:
      
            return  action.success;
        break; 

        default: 
        return state;

    }
};

 
export const successs = ( state = [], action ) => { 
    
    switch(action.type) {
        case SET_SUCCESSS:
      
            return  action.successs;
        break; 

        default: 
        return state;

    }
};

 