
import React, { Component, Fragment } from "react"; 
import {   Link } from 'react-router-dom'; 
import TimeAgo from 'react-timeago';

import { SERVER } from '../../services/api';
 
import { connect } from "react-redux";
// import { getViewYoutube, getCurrentUser } from "../store/actions";
 
  

class ViewYoutube extends Component {
    constructor(props) {
        super(props); 
 
    }

componentWillMount () {


        const { youtube, history } = this.props;

        if ( typeof youtube.title == "undefined") {
          history.push('.');
        }
}
 

 
    

 
    render() {
        const { youtube } = this.props;

        return <> 


 

<div
 
     className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2 text-capitalize">{ youtube.title || "view youtube"}</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <span className="my-auto mr-3 text-muted"><TimeAgo date={youtube.date } /></span>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Youtubes
      </Link>
    </div>
  </div> 






      <div className="row"> 

          <div className="col">

            <div className="row">

              <div className="col-sm-12 col-md-6">

                <div className="form">



                  <div className="form-group">
                      <lable>Title</lable>  
                      <p className="form-control mt-2">{youtube.title}</p> 
                  </div>


                  


                </div>


              </div>

              <div className="col-sm-12 col-md-6">
              <div className="form">

 

                <div className="form-group">
                    <lable>Key</lable>  
                    <p className="form-control mt-2">{youtube.key}</p> 
                </div>

                </div>

                </div>

<div className="col-sm-12 ">
<div className="form">


                  <div className="form-group">
                      <lable>Description</lable>  
                      <p className="form-control mt-2" style={{ minHeight: '100px'}}>{youtube.description}</p> 
                  </div>


              </div>





              </div>

            </div>


            

</div>
</div>

         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    youtube: store.youtube,
    error: store.error
}), {   })(ViewYoutube);


