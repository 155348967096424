import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter} from 'react-router-dom';
// import AuthType from '../pages/AuthPage'; 



import Dashboard from '../pages/Dashboard'; 
import Login from '../pages/Login';


import Test from '../pages/TestPage';




import { getCurrentUser } from '../store/actions';
import Product from '../pages/Product';
import Testimonial from '../pages/Testimonial';
import Youtube from '../pages/Youtube';

import Success from '../pages/Success';
import Settings from '../pages/Settings';



const RouteViews = ({auth, getCurrentUser}) => (<main>
    <Switch>



      
        <Route exact path="/login" render={() => (
            <Login authType="login" isAuthenticated={auth.isAuthenticated} />
        )} />

 
<Route
        exact
        path="/"
        render={props => (<Dashboard  
            {...props}
            isAuthenticated={auth.isAuthenticated} />)} />

<Route
    exact
    path="/dashboard"
    render={props => (<Dashboard  
        {...props}
        isAuthenticated={auth.isAuthenticated} />)} />

<Route
    exact
    path="/settings"
    render={props => (<Settings  
        {...props}
        isAuthenticated={auth.isAuthenticated} />)} />




<Route ex path="/product" component={( props ) => <Product  {...props} isAuthenticated={auth.isAuthenticated} />} />
<Route ex path="/testimonial" component={( props ) => <Testimonial  {...props} isAuthenticated={auth.isAuthenticated} />} />
<Route ex path="/youtube" component={( props ) => <Youtube  {...props} isAuthenticated={auth.isAuthenticated} />} />

<Route ex path="/success" component={( props ) => <Success  {...props} isAuthenticated={auth.isAuthenticated} />} />






{
    /*



        <Route exact path="/register" render={ props => (
            <Register authType="register" history={props.history} isAuthenticated={auth.isAuthenticated} />
        )} />


<Route exact path="/verify/:id" render={ props => (
            <Verify authType="verify" history={props.history} type="url" params={props.match.params} isAuthenticated={auth.isAuthenticated} />
        )} />
 

 
        <Route
            exact
            path="/"
            render={props => (<Home 
                authType={auth.user.type}
                {...props}
                isAuthenticated={auth.isAuthenticated} />)}
        />
 
    */
}
   
        
    </Switch>
</main>);


export default withRouter(connect(store => ({ auth: store.auth }),
{ getCurrentUser },)(RouteViews),);