import api from '../../services/api';
import {  SET_CURRENT_USER, SET_AUTH_USER } from '../actionType';
import { addError, removeError } from './error';

export const setAuthUser = user => ({
    type: SET_AUTH_USER,
    user
});
 
 export const setCurrentUser = user => ({
     type: SET_CURRENT_USER,
     user
 });

 

 export const getCurrentUser = path => {
    return async dispatch => {
        try {
            const user = await api.call('get', `user/${path}`);
            console.log(user);
            dispatch(setCurrentUser(user[0]));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}


  
export const updatePassword = (path, data) => {
    return async dispatch => {
        try {
            const user = await api.call('post', `user/${path}`, data);
            console.log(user.status );
            if(user.status === 1 ) { 
                console.log( user );
                // localStorage.clear();
                // api.setToken(null);
                // dispatch(setAuthUser({}));
                // dispatch(removeError());
            } else {
                dispatch(addError( user.message ));  
            }
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}


  