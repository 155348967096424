import React, { Component, Fragment } from "react";
import { Link} from 'react-router-dom';
import TimeAgo from 'react-timeago';

import { connect } from "react-redux";
import { getYoutubes, setYoutube, removeYoutube } from "../../store/actions";

import { confirmAlert } from 'react-confirm-alert';  
import 'react-confirm-alert/src/react-confirm-alert.css'; 


class AllYoutube extends Component {
constructor(props) {
super(props);

}



componentWillMount() {
  const { getYoutubes } = this.props;

  getYoutubes();


}


actionNow =  ( e, action ) => { 
  const { history, setYoutube, removeYoutube } = this.props;

if( action == "delete") {


  confirmAlert({
    title: 'Confirm',
    message: `Are you sure you want to remove ${ e.user }'s youtube`,
    buttons: [
      {
        label: 'Yes',
        onClick: () => removeYoutube( { id : e.id } )
      },
      {
        label: 'No',
        onClick: () => {}
      }
    ]
  });



  
} else {
  setYoutube( e );  
history.push(`./${action}`);
 
}
 
}




render() {

const  {  youtubes }  = this.props;
return <>

  <div
    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">Youtubes</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `new` } className="btn btn-sm btn-outline-secondary  ">
      <i className="icofont-ui-add mr-2"></i>
      New Youtube
      </Link>
    </div>
  </div>




<div className="row"> 

  <div className="col">
{
  youtubes ?
  youtubes.length < 1 ?

 <div className="w-100 text-center">
 <div className="alert alert-warning"> 

<p className="m-0">nothing to show.</p>

</div>

   </div> :




    <div className="table-responsive">

  


    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">key</th>
          <th scope="col">description</th>
          <th scope="col">Time</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>

      {
        youtubes.map( ( each, index) => 
          <tr key={ index }>
            <th scope="row">{index + 1}</th>
            <td>{ each.title }</td>
            <td>{ each.key }</td>
            <td>{ each.description }</td>
            <td><TimeAgo date={each.date } /></td>
            <td>
<div className="btn-group" role="group" aria-label="Basic example">
  <button onClick={ e => this.actionNow( each, "view" ) } type="button" className="btn btn-primary btn-sm"><i className="icofont-eye-alt"></i></button> 
  <button onClick={ e => this.actionNow( each, "delete" ) } type="button" className="btn btn-danger btn-sm"><i className="icofont-ui-delete"></i></button>
  <button onClick={ e => this.actionNow( each, "edit" ) } type="button" className="btn btn-warning text-white btn-sm"><i className="icofont-ui-edit"></i></button>
</div>
            </td>
          </tr> 
          )
      } 
      </tbody>
    </table>





    </div>





: ""
}


  </div>

</div>

</>

}
}




export default connect(store => ({
auth: store.auth,
youtubes: store.youtubes
}), { getYoutubes, setYoutube, removeYoutube })(AllYoutube);