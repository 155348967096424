
import React, { Component, Fragment } from "react"; 

import { Link} from 'react-router-dom';
import { SERVER } from '../../services/api';

import { connect } from "react-redux"; 

 

import { updateTestimonial } from '../../store/actions';

import {ListValidator, ValidatorForm, TextValidator, TextAreaValidator, ImageSelector} from '../common/Validator/';


class EditTestimonial extends Component {
    constructor(props) {
        super(props); 

        this.state = {
          id: 0,
          user : '',
          type: '', 
          user_image: [],
          old_image: [],
          testimonial: '',
          disabled : false
        }
 
    }

    handleChange =  e => { 
      const newState = {}
      newState[ e.target.name ] =  e.target.value;  
      this.setState( {...newState} );
    }

    handleSubmit = ( e ) => {
      const {    
      id, 
        user,
        type, 
        user_image,
        testimonial,
        old_image } = this.state;
      const { updateTestimonial } = this.props;

       


       const new_testimonial = {
         user,
        type, 
        testimonial,
        euser_images : JSON.stringify( old_image )
       }

       user_image.forEach( (each, index) => {
        new_testimonial[`user_image[${index}]`] = each.crop;
       });
      
      

      console.log( new_testimonial );



this.setState( { disabled : true });

      updateTestimonial( id, new_testimonial );


    }






componentWillReceiveProps (newProps){
  const oldProps = this.props;

  const { toast, history } = this.props;
  const { user } = this.state;
 
  if( newProps.testimonial != oldProps.testimonial && newProps.testimonial ) {

        if( newProps.testimonial.user === user) {
          toast.success("Testimonial successfully updated !", {
               position: toast.POSITION.LEFT_BOTTOM
             });

          
    this.setState( { disabled : false});
 
        }
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}


componentWillMount () {


        const { testimonial, history } = this.props;
        const  testimonialm = this.props.testimonial;

        if ( typeof testimonial.user == "undefined") {
          history.push('.');
        } else {


let { id,   user , type, user_image, testimonial  } = testimonialm;


        this.setState({
          id,
             user ,
        type,
        user_image: [],
        testimonial,
        old_image: [ user_image]
        }  );

          
        }
}





    listImageList = user_images => {

      console.log( user_images );

      if(user_images) {

        return user_images.map( (each, index) => 
                    <div key={index}  className="col-sm-6 col-md-4 pb-3 removable-prev">
                    <span className="cros" onClick={  e => this.removeMe( index )}>
                                        <i className="icofont-close-squared-alt"></i>
                                        </span>

                         <img  className="img-thumbnail mw-100"  src={  `${SERVER}/testimonial/${each}`   }/>
                                          
                    </div>

        );
      }

          }


              removeMe = ( index )=> {
        const { old_image } = this.state;
        old_image.splice(index, 1); 
        this.setState({ old_image });

    }

 

    render() {
        
      const {
        user ,
        type,
        user_image,
        testimonial ,
        old_image,
        disabled
      } = this.state;

const  testimonialm = this.props.testimonial;
        return <> 
 
 <div
 
     className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2 text-capitalize">{ testimonialm.user || "view testimonial"}</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Testimonials
      </Link>
    </div>
  </div> 





      <div className="row"> 

         <div className="col position-relative">

          {
            disabled && <div className="disabled-form" ></div>
          }




          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 col-md-8">

              <div className="form-group">
                <label  className="text-capitalize">User Name</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="user"
                    value={user}
                    validators={['required']}
                    errorMessages={['user is required.']}
                />
            </div>

            <div className="form-group">
                <label className="text-capitalize">User Type</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="type"
                    value={type}
                    validators={['required']}
                    errorMessages={['type is required.']}
                />
            </div>

    



<div className="form-group">
            <label  className="text-capitalize">Image</label>
            <ImageSelector  
            multiple
            cropaspect = { 16 /16 }
            crop={ true }
                name="user_image"  

                value = { user_image }

        onChange={this.handleChange}
        validators={[]}
        errorMessages={[ ]}
            />





<div className="mt-3 border p-2 user_image-review">

<label className="mb-2">Old Images</label>

<div className="row">
{
  this.listImageList( old_image )
}
</div>



</div>

          </div>


 



 


            <div className="form-group">
                <label className="text-capitalize">testimonial</label> 
                <TextAreaValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="testimonial"
                    rows="4"
                    value={testimonial} 
                />
            </div>



            


          </div>






          </div>

 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8">

          <button type="submit" className="btn btn-primary">Update</button>

      </div>
      </div>

         
        </ValidatorForm>


            
          </div>
      </div>










         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    testimonial: store.testimonial,
    error: store.error
}), {  updateTestimonial })(EditTestimonial);



 