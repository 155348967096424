
import React, { Component, Fragment } from "react"; 

import { Link} from 'react-router-dom';

import { connect } from "react-redux"; 

 

import { createProduct } from '../../store/actions';

import {ListValidator, ValidatorForm, TextValidator, TextAreaValidator, ImageSelector} from '../common/Validator/';


class NewProduct extends Component {
    constructor(props) {
        super(props); 

        this.state = {
          name : '',
          title: '',
          feature: [],
          image: [],
          youtube: '',
          description: '',
          disabled : false
        }
 
    }

    handleChange =  e => { 
      const newState = {}
      newState[ e.target.name ] =  e.target.value;  
      this.setState( {...newState} );
    }

    handleSubmit = ( e ) => {
      const {     
        name,
        title,
        feature,
        image,
        youtube,
        description } = this.state;
      const { createProduct } = this.props;

       


       const new_product = {
         name,
        title,
        feature : JSON.stringify(feature), 
        description,
        youtube
       }

       image.forEach( (each, index) => {
        new_product[`image[${index}]`] = each.crop;
       });
      
      

      console.log( new_product );


this.setState( { disabled : true });

      createProduct( new_product );


    }





componentWillReceiveProps (newProps, oldProps){

  const { toast, history } = this.props;
  const { name } = this.state;

 

  if( newProps.product != oldProps.product && newProps.product ) {

        if( newProps.product.name === name) {
          toast.success("Product successfully added !", {
               position: toast.POSITION.LEFT_BOTTOM
             });

          this.setState({
                name : '',
                title: '',
                feature: [],
                image: [],
                description: '',
                youtube: '',
                disabled : false
              });
        }
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}
 

    render() {
        
      const {
        name ,
        title,
        feature,
        image,
        description,
        youtube,
        disabled 
      } = this.state;
 
        return <> 
 

 <div
    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">New Product</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Products
      </Link>
    </div>
  </div>






      <div className="row"> 

          <div className="col position-relative">

          {
            disabled && <div className="disabled-form" ></div>
          }



          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 col-md-8">

              <div className="form-group">
                <label  className="text-capitalize">Name</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="name"
                    value={name}
                    validators={['required']}
                    errorMessages={['name is required.']}
                />
            </div>

            <div className="form-group">
                <label className="text-capitalize">Tilte</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="title"
                    value={title}
                    validators={['required']}
                    errorMessages={['title is required.']}
                />
            </div>

            <div className="form-group">
                        <label  className="text-capitalize">Image</label>
                        <ImageSelector  
                        multiple
                        cropaspect = { 16 /9 }
                        crop={ true }
                            name="image"  

                            value = { image }

                    onChange={this.handleChange}
                    validators={['required']}
                    errorMessages={['image is required.']}
                        />
                      </div>




 


            <div className="form-group">
                <label className="text-capitalize">Features</label> 
                <ListValidator
                    onChange={this.handleChange} 
                    name="feature"
                    value={feature}
                    validators={['required']}
                    errorMessages={['feature is required.']}
                />
            </div>





            <div className="form-group">
                <label className="text-capitalize">youtube key</label> 
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="youtube" 
                    value={youtube} 
                    validators={['required']}
                    errorMessages={['key is required.']}
                />
            </div>




            <div className="form-group">
                <label className="text-capitalize">description</label> 
                <TextAreaValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="description"
                    rows="4"
                    value={description} 
                />
            </div>



            


          </div>






          </div>

 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8">

          <button type="submit" className="btn btn-primary">Submit</button>

      </div>
      </div>

         
        </ValidatorForm>


            
          </div>
      </div>










         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    product: store.product,
    error: store.error
}), {  createProduct })(NewProduct);


