import React, { Component } from "react"; 
import { connect } from "react-redux"; 


import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class ImageCrop extends Component {
    constructor(props) {
        super(props); 

        this.state = { 
            files : props.files,
            newFiles: [],
            file: 0,
            src: null,
            crop: {
            unit: "%",
            width: 30,
            aspect: this.props.cropaspect
            },
            message : '',
            cropedFile : {}
        }
      

        this.style = {
            background: 'rgba(0, 0, 0, 0.54)'           
        }
       
 
    } 
    

    startCrop = () => {
        const { file, files } = this.state;


        if (file > -1 && file < files.length ) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
              this.setState({ src: reader.result })
            );
            reader.readAsDataURL(files[file]);
          }
    }
    nextCrop = e => {


        const value = parseInt( e.target.value );
 

        const { files, cropedFile, croppedImageUrl, newFiles, file } =  this.state;
        newFiles[file] = { src : croppedImageUrl, file: cropedFile,  name : files[ file ].name };
 
 
        const message = ""; 
        this.setState( { newFiles, message }, e => this.copImage(value  ) );

        ;
    }

    copImage = ( file ) => { 
        this.setState( { file }, e =>  this.startCrop() );
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';

        const { files } = this.state;
 
        this.copImage( 0 );



    }

    

    triggExitCrop = () => {
        const { doneCrop } = this.props;

        const { files, croppedImageUrl, newFiles, file } =  this.state;

        if( files.length != newFiles.length ) {
            const message = " crop all selected files.";
            this.setState({ message });
            return;
        }



        const files_new = files.map( (each, index) => { 
            const file_ = typeof newFiles[index] != "undefined" ? newFiles[index].file : undefined; 
            each['crop'] = file_;
            return each;
        });

        
        
         


        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        doneCrop( files_new );
    }


    saveCrop = () => { 
 


        const { files, cropedFile, croppedImageUrl, newFiles, file } =  this.state;
        newFiles[file] = { src : croppedImageUrl, file: cropedFile,  name : files[ file ].name };
        
 

        this.setState( { newFiles } );

        this.setState( { newFiles }, e => this.triggExitCrop() );



    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    closeCrop = ( t = true ) => {
        const { doneCrop } = this.props;
        const { files } = this.state;

        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        if(t)
        doneCrop( files );
      else 
        doneCrop( [] );
    }

 
  makeid = (length) => {
   let result           = '';
   let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   let charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}
    
      // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };
    
      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };
    
      async makeClientCrop(crop) {
        console.warn(crop);
        if (this.imageRef && crop.width && crop.height) {
          const respoNImage = await this.getCroppedImg(
            this.imageRef,
            crop,
            `${this.makeid(9)}.jpeg` 
          );
         const croppedImageUrl = respoNImage.url;
        const cropedFile = respoNImage.file
          this.setState({ croppedImageUrl, cropedFile });
        }
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
              console.log( blob );
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error("Canvas is empty");
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);

            const newFile = new File([blob], fileName);
            resolve( { url : this.fileUrl, file: newFile});
          }, "image/jpeg");

        
        });
      }
    

    render() {

        const { message, newFiles, crop, croppedImageUrl, src, file } = this.state;

        const { files } =  this.props;
   
       
        return <> 


<div className="modal bd-example-modal-xl fade show d-block" id="exampleModal"  style={ this.style }>
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Crop Image</h5>
        <button onClick={ e => this.closeCrop( false ) } type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body" style={{ overflowY: 'auto',  maxHeight: `${window.innerHeight - 200}px` }}>



      <div className="App">
       
       <div className="row">

           <div className="col-sm-12 pb-3">
                <select onChange={ this.nextCrop } className="form-control">
                    {
                        files.map( (each, index) => {
                            return <option selected = { index == file ? true: false } key= {index} value={index}>{ each.name }</option>
                        })
                    }
                </select>
           </div>

           <div className="col-sm-12 col-md-8">

                {src && (
                    <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    className="w-100"
                    />
                )}


           </div>
        <div className="col-sm-12 col-md-4 overflow-hidden">
                    <div className="d-block mb-2">
                                    {croppedImageUrl && (
                                <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
                            )}
                    </div>

                    <div className="row bg-light ">
                                        {
                                            newFiles.map( (each, index ) => {

                                                return <div key={ index } title={ each.name } className="col-sm-4 c-md-3 mt-2"> 
                                                                        {each && (
                                                                        <img alt="Crop" onClick={ e =>  this.copImage( index ) } className="mw-100" style={{ maxWidth: "100%" }} src={each.src} />
                                                                        )}
                                                    </div>
                                            })
                                        }

                    </div>
        </div>

       </div>
        
       
      </div>




      </div>
      <div className="modal-footer">
         {
             message ?  <div className="alert alert-danger text-center d-flex flex-grow-1 m-0 p-2">
                                        <p className="m-0">{message}</p>
          </div> : ""
         }
        <button type="button"  onClick={ e => this.closeCrop( false ) } className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={ this.saveCrop }>Save changes</button>
      </div>
    </div>
  </div>
</div>




         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth
}), { })(ImageCrop);


