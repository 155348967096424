import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'; 
import { connect } from "react-redux"; 
 


class MenuBar extends Component {
    constructor(props) {
        super(props);



        this.state = { 
            users :[]
        }
      


       
 
    }




 
 
 

    

    render() {
        

        return <> 

<nav className={ ` left-menu col-md-2 d-none d-md-block bg-light sidebar  ${ this.props.menuToggle && " menu-left-nav " }` }    >
      <div className="sidebar-sticky">
        <ul className="nav flex-column">



        <li className="nav-item">
          <Link to="/dashboard" className="nav-link text-capitalize " > 
              <span className="icofont-dashboard mr-2"></span>
              dashboard   
            </Link>
          </li>



          <li className="nav-item">
          <Link to="/product/" className="nav-link text-capitalize " > 
              <span className="icofont-box mr-2"></span>
              product   
            </Link>
          </li>

<li className="nav-item">
<Link to="/testimonial/" className="nav-link text-capitalize " > 
    <span className="icofont-users-alt-1 mr-2"></span>
    testimonial   
  </Link>
</li>



<li className="nav-item">
<Link to="/youtube/" className="nav-link text-capitalize " > 
    <span className="icofont-youtube-play mr-2"></span>
    youtube   
  </Link>
</li>

<li className="nav-item">
<Link to="/success/" className="nav-link text-capitalize " > 
    <span className="icofont-brand-target mr-2"></span>
    Success Stories   
  </Link>
</li>


            
        </ul>

 {
  /*

<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
  <span>Saved reports</span>
  <a className="d-flex align-items-center text-muted" href="#">
    <span data-feather="plus-circle"></span>
  </a>
</h6>

  */
 }
        
      </div>
    </nav>









         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth
}), {  })(MenuBar);


