import { SET_YOUTUBE, SET_YOUTUBES  } from '../actionType';


export const youtube = ( state = {}, action ) => { 
    
    switch(action.type) {
        case SET_YOUTUBE:
      
            return  action.youtube;
        break; 

        default: 
        return state;

    }
};

 
export const youtubes = ( state = [], action ) => { 
    
    switch(action.type) {
        case SET_YOUTUBES:
      
            return  action.youtubes;
        break; 

        default: 
        return state;

    }
};

 