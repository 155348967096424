import { SET_TESTIMONIAL, SET_TESTIMONIALS  } from '../actionType';


export const testimonial = ( state = {}, action ) => { 
    
    switch(action.type) {
        case SET_TESTIMONIAL:
      
            return  action.testimonial;
        break; 

        default: 
        return state;

    }
};

 
export const testimonials = ( state = [], action ) => { 
    
    switch(action.type) {
        case SET_TESTIMONIALS:
      
            return  action.testimonials;
        break; 

        default: 
        return state;

    }
};

 