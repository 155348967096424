import React from 'react';
import { Redirect } from 'react-router-dom'; 
import Dashboard from '../components/Dashboard';



 

const Page = ({   history, isAuthenticated }) => {
    if (!isAuthenticated) return <Redirect to="/login" />
 
    return (
        <div className="dashboard">  
             <Dashboard history={history}   />
        </div>
    );
}

 


export default Page;