
import React, { Component, Fragment } from "react"; 

import { Link} from 'react-router-dom';

import { connect } from "react-redux"; 

 

import { createTestimonial } from '../../store/actions';

import {ListValidator, ValidatorForm, TextValidator, TextAreaValidator, ImageSelector} from '../common/Validator/';


class NewTestimonial extends Component {
    constructor(props) {
        super(props); 

        this.state = {
          user : '',
          type: 'User', 
          user_image: [],
          testimonial: '',
          disabled : false
        }
 
    }

    handleChange =  e => { 
      const newState = {}
      newState[ e.target.name ] =  e.target.value;  
      this.setState( {...newState} );
    }

    handleSubmit = ( e ) => {
      const {     
        user,
        type, 
        user_image,
        testimonial } = this.state;
      const { createTestimonial } = this.props;

       


       const new_testimonial = {
         user,
        type, 
        testimonial
       }

       user_image.forEach( (each, index) => {
        new_testimonial[`user_image[${index}]`] = each.crop;
       });
      
      

      console.log( new_testimonial );


this.setState( { disabled : true });

      createTestimonial( new_testimonial );


    }





componentWillReceiveProps (newProps, oldProps){

  const { toast, history } = this.props;
  const { user } = this.state;

 

  if( newProps.testimonial != oldProps.testimonial && newProps.testimonial ) {

        if( newProps.testimonial.user === user) {
          toast.success("Testimonial successfully added !", {
               position: toast.POSITION.LEFT_BOTTOM
             });

          this.setState({
                user : '',
                type: 'User', 
                user_image: [],
                testimonial: '',
                disabled : false
              });
        }
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}
 

    render() {
        
      const {
        user ,
        type, 
        user_image,
        testimonial,
        disabled 
      } = this.state;
 
        return <> 
 

 <div
    className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">New Testimonial</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Testimonials
      </Link>
    </div>
  </div>






      <div className="row"> 

          <div className="col position-relative">

          {
            disabled && <div className="disabled-form" ></div>
          }



          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 col-md-8">

              <div className="form-group">
                <label  className="text-capitalize">User Name</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="user"
                    value={user}
                    validators={['required']}
                    errorMessages={['name is required.']}
                />
            </div>

            <div className="form-group">
                <label className="text-capitalize">Type</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="type"
                    value={type}
                    validators={['required']}
                    errorMessages={['type is required.']}
                />
            </div>

            <div className="form-group">
                        <label  className="text-capitalize">Image</label>
                        <ImageSelector  
                        
                        cropaspect = { 16 /16 }
                        crop={ true }
                            name="user_image"  

                            value = { user_image }

                    onChange={this.handleChange}
                    validators={['required']}
                    errorMessages={['image is required.']}
                        />
                      </div>




 
 

            <div className="form-group">
                <label className="text-capitalize">testimonial</label> 
                <TextAreaValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="testimonial"
                    rows="4"
                    value={testimonial} 
                    validators={['required']}
                    errorMessages={['testimonial is required.']}
                />
            </div>



            


          </div>






          </div>

 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8">

          <button type="submit" className="btn btn-primary">Submit</button>

      </div>
      </div>

         
        </ValidatorForm>


            
          </div>
      </div>










         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    testimonial: store.testimonial,
    error: store.error
}), {  createTestimonial })(NewTestimonial);


