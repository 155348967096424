import api from '../../services/api';
import { SET_SUCCESS, SET_SUCCESSS } from '../actionType';
import { addError, removeError } from './error';


 

 export const setSuccess = success => ({
     type: SET_SUCCESS,
     success
 });
 export const setSuccesss = successs => ({
     type: SET_SUCCESSS,
     successs
 });
 
 

export const createSuccess = data => {
 
    return async dispatch => {
        try { 
            const success = await api.call('post', 'success/new', data); 

                console.warn(success);
            if(success.status == 1) { 
                console.log(success.data);
                dispatch(setSuccess(success.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(success.message))
            }
 

        } catch (err) {
            const error = err.response.data;
            console.log(error);
            dispatch(addError(error.err))
        }
    }
};



export const getSuccesss = () => {
    return async dispatch => {
        try {
            const successs = await api.call('get', `success/all`); 
            dispatch(setSuccesss(successs));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}


export const getSuccess = path => {
    return async dispatch => {
        try {
            const success = await api.call('get', `success/${path}`); 
            dispatch(setSuccess(success));
            dispatch(removeError());
            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));            
        }
    }
}



export const updateSuccess = (id, data) => {
 
    return async dispatch => {
        try{ 
            const success = await api.call( 'post', `success/${id}`, data);
            
                console.warn(success);
            if(success.status == 1) { 
                console.log(success.data);
                dispatch(setSuccess(success.data));
                dispatch(removeError()); 
            } else { 
                dispatch(addError(success.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


export const removeSuccess = ( data ) => {
 
    return async dispatch => {
        try{ 
            const success = await api.call( 'post', `success/del`, data);
            
            if(success.status == 1) { 

                const successs = await api.call('get', `success/all`); 
                dispatch(setSuccesss(successs));
                dispatch(removeError());
                
            } else { 
                dispatch(addError(success.message))
            }

        } catch ( err ) {
            const error = err.response.data;
            dispatch(addError(error.err));
        }
    }
}


 

 
