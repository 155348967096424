import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
 
class ListValidator extends ValidatorComponent {

    constructor ( props) {

        super( props);

        this.state = {
            localValue : '',
            value: '' 
        }
    }

    addItems = ( e ) => {
 
        const { onChange, name } =   this.props;

        let  { value, localValue } = this.state;

        if(  localValue == "") {
            return;
        }
 
        value.push( localValue );
 
        this.setState({   value, localValue : "" });

        onChange( {target: { name, value}} );

    }

    changeInput = ( e ) => { 
        this.setState({ localValue: e.target.value, isValid: true});
    }
    removeItemNow = ( index ) => {

        const { onChange, name } =   this.props;

        let  { value } = this.state;
 
        value.splice(index, 1); 

        onChange( {target: { name, value}} );

    }
 
    render() {
        const { errorMessages, validators, requiredError, validatorListener, value, ...rest } = this.props;
 
        const { localValue } = this.state;
        return (
            <>
            <div>



  <div className="custom-list-input d-flex flex-row" >
    <input
    onChange= { this.changeInput}
    value={ localValue}
    className="form-control "  
                     
                /> 
   <div className="input-group-append">
    <span className="input-group-text" id="inputGroupFileAddon02" onClick={ this.addItems }>Add</span>
  </div>
  </div>
 
  



              
                {this.errorText()}
            </div>

            <div className="mt-2 d-flex flex-column"> 

                {
                    value.map( (each, index) => {

                    return <div key={ index } className="d-flex flex-row p-2 mb-1 border">
                    <div className="flex-grow-1">
                        <p className="my-auto">{ each}</p>
                    </div>
                    <div className="flex-shrink">
                        <span className="cursor-pointer px-3 py-2 " onClick={ e => this.removeItemNow( index) }>
                              <i className="icofont-close-squared-alt"></i>
                        </span>
                    </div>
                </div>

                    })
                }

            </div>
            </>
        );
    }
 
    errorText() {
        const { isValid } = this.state;
 
        if (isValid) {
            return null;
        }
 
        return (
            <div style={{ color: 'red' }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
 
export default ListValidator;