
import React, { Component, Fragment } from "react"; 

import { Link} from 'react-router-dom';
import { SERVER } from '../../services/api';

import { connect } from "react-redux"; 

 

import { updateYoutube } from '../../store/actions';

import {ListValidator, ValidatorForm, TextValidator, TextAreaValidator, ImageSelector} from '../common/Validator/';


class EditYoutube extends Component {
    constructor(props) {
        super(props); 

        this.state = {
          id: 0,
          title : '',
          key: '',  
          description: '',
          disabled : false
        }
 
    }

    handleChange =  e => { 
      const newState = {}
      newState[ e.target.name ] =  e.target.value;  
      this.setState( {...newState} );
    }

    handleSubmit = ( e ) => {
      const {    
      id, 
        title,
        key,  
        description,
        old_image } = this.state;
      const { updateYoutube } = this.props;

       


       const new_youtube = {
         title,
        key, 
        description 
       }
 
      

      console.log( new_youtube );



this.setState( { disabled : true });

      updateYoutube( id, new_youtube );


    }






componentWillReceiveProps (newProps){
  const oldProps = this.props;

  const { toast, history } = this.props;
  const { title } = this.state;
 
  if( newProps.youtube != oldProps.youtube && newProps.youtube ) {

        if( newProps.youtube.title === title) {
          toast.success("Youtube successfully updated !", {
               position: toast.POSITION.LEFT_BOTTOM
             });

          
    this.setState( { disabled : false});
 
        }
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}


componentWillMount () {


        const { youtube, history } = this.props;
        const  youtubem = this.props.youtube;

        if ( typeof youtube.title == "undefined") {
          history.push('.');
        } else {


let { id,   title , key,   description  } = youtubem;


        this.setState({
          id,
             title ,
        key, 
        description
        }  );

          
        }
}


 


              removeMe = ( index )=> {
        const { old_image } = this.state;
        old_image.splice(index, 1); 
        this.setState({ old_image });

    }

 

    render() {
        
      const {
        title ,
        key, 
        description ,
        old_image,
        disabled
      } = this.state;

const  youtubem = this.props.youtube;
        return <> 
 
 <div
 
     className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2 text-capitalize">{ youtubem.title || "view youtube"}</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Youtubes
      </Link>
    </div>
  </div> 





      <div className="row"> 

         <div className="col position-relative">

          {
            disabled && <div className="disabled-form" ></div>
          }




          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 col-md-6">

              <div className="form-group">
                <label  className="text-capitalize">Title</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="title"
                    value={title}
                    validators={['required']}
                    errorMessages={['title is required.']}
                />
            </div>
            </div>

            <div className="col-sm-12 col-md-6">


            <div className="form-group">
                <label className="text-capitalize">Youtube Key</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="key"
                    value={key}
                    validators={['required']}
                    errorMessages={['key is required.']}
                />
            </div>

    

 
 

            </div>

            </div>

            <div className="row  ">
 


 
            <div className="col-sm-12 ">

             



 


            <div className="form-group">
                <label className="text-capitalize">description</label> 
                <TextAreaValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="description"
                    rows="4"
                    value={description} 
                />
            </div>



            


          </div>
          </div>





 
 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8 text-right">

          <button key="submit" className="btn btn-primary">Update</button>

      </div>
      </div>

         
        </ValidatorForm>


            
          </div>
      </div>










         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    youtube: store.youtube,
    error: store.error
}), {  updateYoutube })(EditYoutube);



 