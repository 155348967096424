
import React, { Component, Fragment } from "react"; 

import { Link} from 'react-router-dom';
import { SERVER } from '../../services/api';

import { connect } from "react-redux"; 

 

import { updateProduct } from '../../store/actions';

import {ListValidator, ValidatorForm, TextValidator, TextAreaValidator, ImageSelector} from '../common/Validator/';


class EditProduct extends Component {
    constructor(props) {
        super(props); 

        this.state = {
          id: 0,
          name : '',
          title: '',
          feature: [],
          image: [],
          old_image: [],
          youtube: '',
          description: '',
          disabled : false
        }
 
    }

    handleChange =  e => { 
      const newState = {}
      newState[ e.target.name ] =  e.target.value;  
      this.setState( {...newState} );
    }

    handleSubmit = ( e ) => {
      const {    
      id, 
        name,
        title,
        feature,
        image,
        youtube,
        description,
        old_image } = this.state;
      const { updateProduct } = this.props;

       


       const new_product = {
         name,
        title,
        feature : JSON.stringify(feature), 
        youtube,
        description,
        eimages : JSON.stringify( old_image )
       }

       image.forEach( (each, index) => {
        new_product[`image[${index}]`] = each.crop;
       });
      
      

      console.log( new_product );



this.setState( { disabled : true });

      updateProduct( id, new_product );


    }





componentWillReceiveProps (newProps){
  const oldProps = this.props;

  const { toast, history } = this.props;
  const { name } = this.state;

 
  if( newProps.product != oldProps.product && newProps.product ) {

        if( newProps.product.name  && newProps.product.name === name) {
          toast.success("Product successfully updated !", {
               position: toast.POSITION.LEFT_BOTTOM
             });

          
    this.setState( { disabled : false});
 
        }
  }
  if( newProps.error != oldProps.error && newProps.error.message ) {

    toast.error( newProps.error.message, {
         position: toast.POSITION.LEFT_BOTTOM
       });
    this.setState( { disabled : false});

  }
  






}


componentWillMount () {


        const { product, history } = this.props;

        if ( typeof product.name == "undefined") {
          history.push('.');
        } else {


let { id,   name , title, feature, image, youtube, description  } = product;

try{
  feature = JSON.parse( feature );

} catch( err ){}
        this.setState({
          id,
             name ,
        title,
        feature,
        image: [],
        youtube,
        description,
        old_image: [...image]
        }  );

          
        }
}





    listImageList = images => {

      console.log( images );

      if(images) {

        return images.map( (each, index) => 
                    <div key={index}  className="col-sm-6 col-md-4 pb-3 removable-prev">
                    <span className="cros" onClick={  e => this.removeMe( index )}>
                                        <i className="icofont-close-squared-alt"></i>
                                        </span>

                         <img  className="img-thumbnail mw-100"  src={  `${SERVER}/device/${each}`   }/>
                                          
                    </div>

        );
      }

          }


              removeMe = ( index )=> {
        const { old_image } = this.state;
        old_image.splice(index, 1); 
        this.setState({ old_image });

    }

 

    render() {
        
      const {
        name ,
        title,
        feature,
        image,
        youtube,
        description ,
        old_image,
        disabled
      } = this.state;

const { product  } = this.props;
        return <> 
 
 <div
 
     className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2 text-capitalize">{ product.name || "view product"}</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Products
      </Link>
    </div>
  </div> 





      <div className="row"> 

         <div className="col position-relative">

          {
            disabled && <div className="disabled-form" ></div>
          }




          <ValidatorForm
            ref="form"
            onSubmit={this.handleSubmit}
        >

          <div className="row  justify-content-center">
 



            <div className="col-sm-12 col-md-8">

              <div className="form-group">
                <label  className="text-capitalize">Name</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="name"
                    value={name}
                    validators={['required']}
                    errorMessages={['name is required.']}
                />
            </div>

            <div className="form-group">
                <label className="text-capitalize">Tilte</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="title"
                    value={title}
                    validators={['required']}
                    errorMessages={['title is required.']}
                />
            </div>

    



<div className="form-group">
            <label  className="text-capitalize">Image</label>
            <ImageSelector  
            multiple
            cropaspect = { 16 /9 }
            crop={ true }
                name="image"  

                value = { image }

        onChange={this.handleChange}
        validators={[]}
        errorMessages={[ ]}
            />





<div className="mt-3 border p-2 image-review">

<label className="mb-2">Old Images</label>

<div className="row">
{
  this.listImageList( old_image )
}
</div>



</div>

          </div>


 



 


            <div className="form-group">
                <label className="text-capitalize">Features</label> 
                <ListValidator
                    onChange={this.handleChange} 
                    name="feature"
                    value={feature}
                    validators={['required']}
                    errorMessages={['feature is required.']}
                />
            </div>



            <div className="form-group">
                <label className="text-capitalize">description</label> 
                <TextAreaValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="description"
                    rows="4"
                    value={description} 
                />
            </div>




            <div className="form-group">
                <label className="text-capitalize">Youtube key</label>
                <TextValidator
                    onChange={this.handleChange}
                    className="form-control" 
                    name="youtube"
                    value={youtube}
                    validators={['required']}
                    errorMessages={['key is required.']}
                />
            </div>

            


          </div>






          </div>

 

          <div className="row  justify-content-center">



          <div className="col-sm-12 col-md-8">

          <button type="submit" className="btn btn-primary">Update</button>

      </div>
      </div>

         
        </ValidatorForm>


            
          </div>
      </div>










         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    product: store.product,
    error: store.error
}), {  updateProduct })(EditProduct);



 