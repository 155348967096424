import { addError, removeError } from './error';
import {
    SET_AUTH_USER,
    SET_NEW_USER
} from '../actionType';
import api from '../../services/api';


export const setAuthUser = user => ({
    type: SET_AUTH_USER,
    user
});

export const setNewUser = user => ({
    type: SET_NEW_USER,
    user
});


export const setToken = token => {
    api.setToken(token);
};

export const logout = () => {
    return dispatch => {
        localStorage.clear();
        api.setToken(null);
        dispatch(setAuthUser({}));
        dispatch(removeError());

    }
}

export const authUser = (path, data) => {
    return async dispatch => {
        try {
            const user = await api.call('post', `auth/${path}`, data);
            const { token } = user;

            if( user.status  == 1 ) {
                localStorage.setItem('userToken', JSON.stringify( user ));
                api.setToken(token);
                dispatch(setAuthUser(user));
                dispatch(removeError());
            } else { 
            dispatch(addError( user.message ));  
            }

            
        } catch (err) {
            const error = err.response.data;
            dispatch(addError(error.err));  
        }
    }
}

 



export const verifyEmail = data => {

    return async dispatch => {
        try { 
            const user = await api.call('post', 'auth/verify', data);
            
            dispatch(setNewUser(user));
            dispatch(removeError());

        } catch (err) {
            console.log(error);
            const error = err.response.data;
            console.log(error);
            dispatch(addError(error.err))
        }
    }
};



export const clearError = () => {
    return async dispatch => {
        try {  
            dispatch(removeError());

        } catch (err) {
            const error = err.response.data;
            console.log(error);
            dispatch(addError(error.err))
        }
    }
}