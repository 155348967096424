import React from 'react';
import { Redirect } from 'react-router-dom'; 
import Settings from '../components/Settings';



 

const Page = ({   history, isAuthenticated }) => {
    if (!isAuthenticated) return <Redirect to="/login" />
 
    return (
        <div className="settings">  
             <Settings history={history}   />
        </div>
    );
}

 


export default Page;