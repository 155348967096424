import { SET_PRODUCT, SET_PRODUCTS  } from '../actionType';


export const product = ( state = {}, action ) => { 
    
    switch(action.type) {
        case SET_PRODUCT:
      
            return  action.product;
        break; 

        default: 
        return state;

    }
};

 
export const products = ( state = [], action ) => { 
    
    switch(action.type) {
        case SET_PRODUCTS:
      
            return  action.products;
        break; 

        default: 
        return state;

    }
};

 