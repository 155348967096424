import React, {   Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom'; 

import decode from 'jwt-decode';



import { store } from '../store'; 
import { setAuthUser,  setToken } from '../store/actions'; 
 

// import ErrorMessage from '../components/ErrorMessage';
import RouteViews from '../containers/RouteViews'; 
import 'react-toastify/dist/ReactToastify.css';

if(localStorage.userToken) {

    try {

        const userToken = JSON.parse(localStorage.userToken); 
        const { token } = userToken;
        setToken( token ); 

        store.dispatch(setAuthUser(userToken ));
    } catch (err) {
        store.dispatch(setAuthUser({})); 
    }

}






const App = () => (

    <Provider store={store}>
        <Router>
            <Fragment>  
                <RouteViews />  
            </Fragment>
        </Router>
    </Provider>
)





export default App;