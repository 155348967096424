import React, { Component } from "react";
import { Link } from 'react-router-dom'; 
import { connect } from "react-redux"; 

import { logout }  from '../../store/actions';


class NavBar extends Component {
    constructor(props) {
        super(props);



        this.state = { 
            users :[]
        }
      


       
 
    } 

    toggleMenu = () => {
      const { doTogleMenu, menuToggle } = this.props;
      console.log(doTogleMenu, menuToggle);
      doTogleMenu( ! menuToggle );
    } 

    render() {
        

        return <> 



 <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <div className="d-flex w-100">
    <div className="flex-grow-1"><a className="navbar-brand col-sm-3 col-md-2 mr-0 bg-transparent" style={{    boxShadow: 'none' }} href=".">Punarjeeva</a></div>
    <div className="d-flex d-md-none pr-3">
    <button className="navbar-toggler  my-auto" onClick={ e => this.toggleMenu() } type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icofont-navigation-menu"></span>
                        </button>

    </div>
  </div>
  <input className="form-control d-none form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"/>
  <ul className="navbar-nav px-3 d-flex flex-row ">  
  <li className="nav-item text-nowrap">
  <Link to="/settings" className="nav-link cursor-pointer mr-2" title="Settings"  > 
  <span className="icofont-ui-settings mr-2"></span>
            </Link> 
    </li>
  
    <li className="nav-item text-nowrap">
      <a className="nav-link cursor-pointer" onClick={ this.props.logout} title="Sign out">
      <span className="icofont-exit mr-2"></span>
        
        </a>
    </li>
  </ul>
</nav> 





        </>
 
    } 
}




export default connect(store => ({
    auth: store.auth
}), { logout })(NavBar);


