
import React, { Component, Fragment } from "react"; 
import {   Link } from 'react-router-dom'; 
import TimeAgo from 'react-timeago';

import { SERVER } from '../../services/api';
 
import { connect } from "react-redux";
// import { getViewProduct, getCurrentUser } from "../store/actions";
 
  

class ViewProduct extends Component {
    constructor(props) {
        super(props); 
 
    }

componentWillMount () {


        const { product, history } = this.props;

        if ( typeof product.name == "undefined") {
          history.push('.');
        }
}
 

 featureList = (feature) => {



try{
  feature =  JSON.parse( feature );

  console.log( feature );
  return feature.map( (each, index ) => {

    return <p key={index} className="form-control mt-2"><strong className="mr-2"><i className="icofont-caret-right"></i></strong>{ each }</p> 

  })

} catch( err ) { console.log( err )}

 
 }

    


    listImageList = images => {

      console.log( images );

      if(images) {

        return images.map( (each, index) => 
                    <div key={index}  className="col-sm-6 col-md-4 pb-3">
                         <img  className="img-thumbnail mw-100"  src={  `${SERVER}/device/${each}`   }/>
                                          
                    </div>

        );
      }

          }

    render() {
        const { product } = this.props;

        return <> 


 

<div
 
     className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2 text-capitalize">{ product.name || "view product"}</h1>
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">

      </div>
      <span className="my-auto mr-3 text-muted"><TimeAgo date={product.date } /></span>
      <Link to={ `.` } className="btn btn-sm btn-outline-secondary  ">
       <i className="icofont-box mr-2"></i>
      All Products
      </Link>
    </div>
  </div> 






      <div className="row"> 

          <div className="col">

            <div className="row">

              <div className="col-sm-12 col-md-6">

                <div className="form">



                  <div className="form-group">
                      <lable>Name</lable>  
                      <p className="form-control mt-2">{product.name}</p> 
                  </div>


                  <div className="form-group">
                      <lable>Feature</lable>  
                      {
                        this.featureList( product.feature)
                      }
                  </div>



                </div>


              </div>

              <div className="col-sm-12 col-md-6">
              <div className="form">

 

                <div className="form-group">
                    <lable>Title</lable>  
                    <p className="form-control mt-2">{product.title}</p> 
                </div>

                <div className="form-group">
                    <lable>Youtube Key</lable>  
                    <p className="form-control mt-2">{product.youtube}</p> 
                </div>




                  <div className="form-group">
                      <lable>Description</lable>  
                      <p className="form-control mt-2" style={{ minHeight: '100px'}}>{product.description}</p> 
                  </div>


              </div>





              </div>

            </div>


               <div className="row mt-3">

              <div className="col-sm-12">

                <lable> Image </lable> 


                <div className="row  mt-2">

                  {
                    this.listImageList( product.image )
                  }

                </div>

              </div>
              </div>

</div>
</div>

         </>
 
    } 
}




export default connect(store => ({
    auth: store.auth,
    product: store.product,
    error: store.error
}), {   })(ViewProduct);


